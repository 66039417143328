import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import AltSection from '../components/altSection'
import TipSection from '../components/tipSection'
import CiSection from '../components/ciSection'

const Careers = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.alt}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext="Submit your resume"
          ctaslug="/careers/submit-your-resume/"          
          type="root"
        />

        {post.frontmatter.section &&
          <section className="bg-white">
            <MDBContainer>
              <div className="careers-overview">
                {post.frontmatter.section.map((sections, index) => {
                  return (
                    <AltSection
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink}
                      colour={sections.btncolour}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

        {post.frontmatter.tip &&
          <section className="bg-light-blue">
            <MDBContainer>
              <div>
                {post.frontmatter.tip.map((tips, index) => {
                  return (
                    <TipSection
                      key={index}
                      title={tips.title}
                      subtitle={tips.subtitle}
                      description={tips.description}
                      card={tips.card}
                      ctadescription={tips.ctadescription}
                      ctatext={tips.ctatext}
                      ctalink={tips.ctalink}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }

        {post.frontmatter.ci && 
          <section className="bg-white">
            <MDBContainer>
              <div>
                {post.frontmatter.ci.map((cis, index) => {
                  return (
                    <CiSection
                      key={index}
                      title={cis.title}
                      subtitle={cis.subtitle}
                      description={cis.description}
                      card={cis.card}
                      ctadescription={cis.ctadescription}
                      ctatext={cis.ctatext}
                      ctalink={cis.ctalink}
                    />
                  )
                })}
              </div>
            </MDBContainer>
          </section>
        }
      </Layout>
    </div>
  )
}
export default Careers

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "Careers" } }) {
      frontmatter {
        meta {
          title
          description 
        }         
        name
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90)
            }
          }
          alttext
          placement
          btnlink
          btncolour
        }
        tip {
          title
          subtitle
          description
          card {
            alttext
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 780, quality: 90) 
              }
            }
          }
          ctadescription
          ctatext
          ctalink
        }
        ci {
          title
          subtitle
          description
          card {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 70, height: 70, quality: 90) 
              }
            }
            title
            alttext
            linktext
            link
          }
          ctadescription
          ctatext
          ctalink
        }
      }
    }
  }
`